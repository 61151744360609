import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {
  appGreen,
  appRedLight,
  baseBorder,
  dividerGrey,
  navbarBlue,
  placeholderGrey,
} from "../../../../Design/SourcesJS/themes";
import ActionMenu from "../../Menu/ActionMenu";
import IconButton from "../../Buttons/IconButton";
import DeleteForeverRounded from "@mui/icons-material/DeleteForeverRounded";
import EditRounded from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import { getNumberVisual } from "./FakturaAddTable/Calculations/FakturaCalcLayout";
import { currenciesList } from "../../../Data/currencies";
import isValidDate, { isSameDay } from "../../../../Classes/isValidDate";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import { withRouter } from "../../../../Routes/withRouter";
import { theme } from "../../../../../App/App";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { NOT_SELECTABLE } from "../../../../Classes/selectStyle";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import Tooltip from "../../Base/Tooltip";

const DoubleLineInvoice = React.lazy(() =>
  import("../MobileTables/DoubleLineInvoice")
);

const SCREEN_SIZE = 25;
const MIN_WIDTH = { xs: "740px", sm: "756px", md: "756px" };

const centerGridContent = {
  align: "center",
  justify: "center",
  alignItems: "center",
};

const tableScrollbarBottom = {
  borderBottomLeftRadius: { xs: "6px", sm: "6px", md: "12px" },
  borderBottomRightRadius: { xs: "6px", sm: "6px", md: "12px" },
  "&::-webkit-scrollbar": {
    display: "block",
    height: "6px",
    scrollbarGutter: "stable both-edges",
  },
  "&::-webkit-scrollbar-track": {
    display: "block",
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    scrollbarGutter: "stable both-edges",
  },
  "&::-webkit-scrollbar-thumb": {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
    scrollbarGutter: "stable both-edges",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#ccc #f1f1f1",
};

function roundToTwo(num) {
  return +(Math.round(parseFloat(num) + Number.EPSILON + "e+2") + "e-2");
}

export function dateToVisual(oldDate) {
  if (!oldDate) return "";
  try {
    let date = new Date(oldDate);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  } catch (err) {
    return oldDate;
  }
}

export function timestampToDate(timestamp) {
  try {
    if (isValidDate(timestamp)) {
      return timestamp;
    }
    let date = new Date(timestamp.toDate());
    if (!isValidDate(date)) {
      throw new Error("Invalid Date");
    }
    return date;
  } catch (err) {
    let seconds = timestamp?.seconds || timestamp?._seconds;
    if (seconds) {
      let date = new Date(seconds * 1000);
      return date;
    } else {
      return null;
    }
  }
}

function isFirebaseTimestamp(value) {
  // Check if the value is an object
  if (typeof value === "object" && value !== null) {
    // Check if the object has the expected properties of a Firebase Timestamp
    return (
      value.hasOwnProperty("_seconds") &&
      value.hasOwnProperty("_nanoseconds") &&
      typeof value._seconds === "number" &&
      typeof value._nanoseconds === "number"
    );
  }

  return false;
}

export function timestampToDateVisual(timestamp) {
  if (!timestamp) return "-";
  try {
    if (isFirebaseTimestamp(timestamp)) {
      return dateToVisual(timestampToDate(timestamp));
    } else if (isValidDate(new Date(timestamp))) return dateToVisual(timestamp);
  } catch (err) {}
  return dateToVisual(timestampToDate(timestamp));
}

export function getIsoFromIndex(index) {
  return currenciesList[index].iso;
}

export function getIndexFromIso(iso) {
  let finalIso = iso || "CZK";
  var elementPos = currenciesList
    .map(function (x) {
      return x.iso;
    })
    .indexOf(finalIso);
  if (elementPos < 0) return 0;
  return elementPos;
}

function getLeftBorder(data) {
  if (data.cxDate) return `4px solid ${placeholderGrey}`;
  let dueDate = timestampToDate(data.dueDate);
  let dateNow = new Date();

  if (data.payDate) {
    return `4px solid ${appGreen}`; //false;
  }

  if (dueDate < dateNow && !isSameDay(dueDate, dateNow)) {
    return `4px solid ${appRedLight}`;
  }
  return false;
}

function getLeftColor(data) {
  if (data.cxDate) return placeholderGrey;
  let dueDate = timestampToDate(data.dueDate);
  let dateNow = new Date();

  if (data.payDate) {
    return appGreen;
  }

  if (dueDate < dateNow && !isSameDay(dueDate, dateNow)) {
    return appRedLight;
  }
  return "transparent";
}

function FakturaTable(props) {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const navigate = useNavigate();
  let {
    dataList = [],
    isPlatce,
    sxPropx = {},
    onDeleteItem,
    onPayButtonClick,
    removeKontaktIcon = false,
    page = 1,
    level = 0,
    sortObjectChange,
    sortObject,
    samePerson = false,
  } = props;
  console.log("dataList", dataList);
  function getCostVisual(cost, iso = null) {
    let finalCost = roundToTwo(cost);
    if (!finalCost) return "-";
    if (finalCost.toString().length > 10) {
      return `${finalCost.toExponential(2)} Kč`;
    }
    return `${getNumberVisual(finalCost, getIndexFromIso(iso))}`;
  }

  const tableRowStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderRight: `1px solid ${dividerGrey}`,
    borderTop: `1px solid ${dividerGrey}`,
  };

  const paddingLeft = { xs: "15px", sm: "20px", md: "35px", lg: "45px" };

  const textStyle = {
    paddingLeft,
    //paddingRight: "3px",
  };

  function getActionMenu(index) {
    return [
      {
        text: "Odstranit",
        icon: <DeleteForeverRounded />,
        hide: Boolean(level == 0),
        tooltip: "Tímto tlačítkem můžete tuto fakturu smazat.",
        id: 0,
      },
      {
        text: "Upravit",
        icon: <EditRounded />,
        tooltip: "Tímto tlačítkem můžete upravit obsah faktury.",
        id: 1,
      },
      {
        text: dataList[index]?.payDate ? "Změnit úhradu" : "Zaznamenat úhradu",
        icon: <CreditScoreRoundedIcon />,
        tooltip: dataList[index]?.payDate
          ? "Zde můžete změnit datum úhrady."
          : "Zde můžete označit fakturu jako uhrazenou a zadat datum úhrady.",
        id: 2,
      },
      dataList?.[index]?.typ == 1 && {
        text: "Doplnit fakturu",
        icon: <NoteAddRoundedIcon />,
        tooltip:
          "Tímto tlačítkem můžete k zálohovce doplnit odpovídající fakturu.",
        id: 3,
      },
      /*isPlatce == 1 &&
        dataList?.[index]?.typ != 1 && {
          text: "Opravný doklad",
          icon: <BuildRoundedIcon />,
          tooltip: "Zde můžete vytvořit opravný daňový doklad.",
          id: 4,
        },*/
    ];
  }

  // Do actions by actionId
  function onClickGetIndex(buttonIndex, lineIndex, actionId) {
    const realIndex = (page - 1) * 10 + lineIndex;

    switch (actionId) {
      case 0:
        onDeleteItem(
          dataList[lineIndex].fakturaUid,
          dataList[lineIndex].listUid
        );
        return;
      case 1:
        navigate("/faktura/uprava", {
          state: {
            data: {
              control: {
                fakturaUid: dataList[lineIndex].fakturaUid,
                listUid: dataList[lineIndex].listUid,
              },
            },
          },
        });
        return;
      case 2:
        if (!onPayButtonClick) return;
        onPayButtonClick(dataList[lineIndex], realIndex);
        return;
      case 3:
        navigate("/faktura/duplikace", {
          state: {
            data: {
              control: {
                fakturaUid: dataList[lineIndex].fakturaUid,
                listUid: dataList[lineIndex].listUid,
                duplicate: true,
                zalohovka: true,
              },
            },
          },
        });
        return;
      case 4:
        navigate("/faktura/nova", {
          state: {
            data: {
              control: {
                fakturaUid: dataList[lineIndex].fakturaUid,
                listUid: dataList[lineIndex].listUid,
                odd: true,
              },
            },
          },
        });
        return;
      default:
        return;
    }
  }

  if (mobile) {
    return (
      <>
        {dataList.map((line, index) => (
          <Box
            sx={{ mt: index !== 0 ? "14px" : "0px" }}
            key={"FakTablePhone" + index}
          >
            <DoubleLineInvoice
              tooltip={
                <span style={{ fontWeight: "normal" }}>
                  {line.typ == 1 && (
                    <>
                      <b style={{ marginBottom: "10px" }}>Zálohová faktura</b>
                      <br />
                    </>
                  )}
                  <b>Datum splatnosti</b>
                  <br />
                  {timestampToDateVisual(line.dueDate)}
                  <br />
                  {line.payDate && (
                    <>
                      <b>Datum úhrady</b>
                      <br />
                      {timestampToDateVisual(line.payDate)}
                    </>
                  )}
                </span>
              }
              title={
                line.typ == 1
                  ? `Zálohová faktura č. ${line.inv}`
                  : `Faktura č. ${line.inv}`
              }
              text={line.name}
              subText={
                line.tax
                  ? `${getCostVisual(
                      line.tax ? line.price + line.tax : line.price,
                      line.iso
                    )} vč. DPH`
                  : getCostVisual(line.price, line.iso)
              }
              actionList={getActionMenu(index).filter(Boolean)}
              onClickGetIndex={onClickGetIndex}
              onClick={() => {
                navigate("/faktura/detail", {
                  state: { fakturaUid: dataList[index].fakturaUid },
                });
              }}
              index={index}
              onPersonClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/adresar/detail?uid=${line.customer}`);
              }}
              leftColor={getLeftColor(line)}
              typ={line.typ}
            />
          </Box>
        ))}
      </>
    );
  }

  const headerParams = {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    minHeight: "78px",
    cursor: "pointer",
    "&:hover": { bgcolor: "#eee" },
    position: "relative",
  };

  const onHeaderClick = (name) => {
    if (sortObject != null && name in sortObject) {
      if (!Boolean(sortObject[name])) {
        sortObjectChange(null);
      } else {
        sortObjectChange({
          [name]: !Boolean(sortObject[name]),
        });
      }
    } else {
      sortObjectChange({
        [name]: true,
      });
    }
  };

  const HeaderArrow = ({ name, down = false }) => {
    return (
      <>
        {sortObject != null && name in sortObject && (
          <Box
            sx={[
              {
                position: down ? "absolute" : "static",
                height: "17px",
                width: "17px",
                ml: down ? "0px" : "8px",
                mr: down ? { xs: "0px" } : paddingLeft,
              },
              down && {
                bottom: 10,
                mx: "auto !important",
                left: "50%",
                transform: "translateX(-50%)",
              },
            ]}
          >
            <ArrowBackIosRoundedIcon
              sx={{
                color: navbarBlue,
                transform: Boolean(sortObject[name])
                  ? "rotate(270deg)"
                  : "rotate(90deg)",
                height: "17px",
                width: "17px",
              }}
            />
          </Box>
        )}
      </>
    );
  };

  const odberatelLine = isPlatce == 1 ? 8 : 12;

  return (
    <Grid
      container
      spacing={0}
      columns={SCREEN_SIZE}
      {...centerGridContent}
      sx={{
        display: "flex",
        position: "relative",
        width: "100%",
        overflowX: { xs: "auto", sm: "auto", md: "hidden" },
        overflowY: "hidden",
        //spacing: 0,
        //justify: "space-around",
        justifyContent: "flex-start",
        mt: "70px",
        boxShadow: "1px 2px 5px 0px rgba(114,117,122,0.2)",
        borderRadius: "12px",
        border: baseBorder,
        backgroundColor: "#FFF", //"#F2F1F1",
        ...tableScrollbarBottom,
        ...sxPropx,
      }}
    >
      <Grid
        container
        item
        columns={SCREEN_SIZE}
        sx={{
          //my: "32px",
          minWidth: MIN_WIDTH,
          minHeight: "78px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "center",
          ...NOT_SELECTABLE,
        }}
      >
        <Grid
          item
          xs={4}
          sm={4}
          sx={{
            ...headerParams,
          }}
          onClick={() => {
            onHeaderClick("faktura");
          }}
        >
          <Typography variant="tableHeader" sx={{ ...textStyle }}>
            Faktura
          </Typography>
          <HeaderArrow name={"faktura"} />
        </Grid>

        <Grid
          item
          xs={odberatelLine}
          sm={odberatelLine}
          sx={samePerson ? {} : { ...headerParams }}
          onClick={() => {
            if (samePerson) return;
            onHeaderClick("odberatel");
          }}
        >
          <Typography variant="tableHeader" sx={{ ...textStyle }}>
            Odběratel
          </Typography>
          <HeaderArrow name={"odberatel"} />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          sx={
            isPlatce == 1
              ? {
                  ...headerParams,
                  justifyContent: "center",
                  alignItems: "normal",
                  alignContent: "normal",
                  marginLeft: "0px",
                  flexDirection: "column",
                }
              : {
                  ...headerParams,
                }
          }
          onClick={() => {
            onHeaderClick("price");
          }}
        >
          <Typography
            variant="tableHeader"
            sx={{ ...textStyle, marginLeft: "0px" }}
          >
            {isPlatce == 1 ? "Cena bez DPH" : "Cena"}
          </Typography>
          <HeaderArrow name={"price"} down={isPlatce == 1} />
        </Grid>
        {isPlatce == 1 && (
          <Grid
            item
            xs={4}
            sm={4}
            sx={{
              ...headerParams,
              justifyContent: "center",
              alignItems: "normal",
              alignContent: "normal",
              marginLeft: "0px",
              flexDirection: "column",
            }}
            onClick={() => {
              onHeaderClick("priceDph");
            }}
          >
            <Typography
              variant="tableHeader"
              sx={{ ...textStyle, marginLeft: "0px" }}
            >
              Cena vč. DPH
            </Typography>
            <HeaderArrow name={"priceDph"} down />
          </Grid>
        )}

        <Grid
          item
          xs={3}
          sm={3}
          sx={{
            ...headerParams,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
          onClick={() => {
            onHeaderClick("splatnost");
          }}
        >
          <Typography variant="tableHeader" sx={{ textAlign: "center" }}>
            Splatnost
          </Typography>
          <HeaderArrow name={"splatnost"} down />
        </Grid>

        <Grid item xs={2} sm={2} sx={{}}>
          <Typography variant="tableHeader" sx={{ textAlign: "center" }}>
            Akce
          </Typography>
        </Grid>
      </Grid>
      {dataList.map((line, index) => (
        <Grid
          key={"FakturaTableLine" + index}
          container
          alignItems="center"
          justifyContent="center"
          item
          columns={SCREEN_SIZE}
          sx={{
            position: "relative",
            height: "48px",
            overflow: "visible",
            minWidth: MIN_WIDTH,
            width: "100%",
            borderBottomLeftRadius:
              index == dataList.length - 1
                ? { xs: "0px", sm: "0px", md: "12px" }
                : "0px",
            "&:hover": {
              borderRight: `4px solid ${navbarBlue}`,
              cursor: "pointer",
              borderBottomLeftRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
              borderBottomRightRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
            },
            borderBottom:
              index === dataList.length - 1
                ? { xs: `1px solid ${dividerGrey}`, sm: 0 }
                : 0,
          }}
          // Line click
          onClick={() => {
            navigate("/faktura/detail", {
              state: { fakturaUid: dataList[index].fakturaUid },
            });
          }}
        >
          <Grid
            item
            xs={4}
            sm={4}
            sx={{
              ...tableRowStyle,
              borderLeft: getLeftBorder(line) || "none",
              borderBottomLeftRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
            }}
          >
            <Tooltip
              text={
                line?.inv && line?.inv?.length > 10 ? (
                  <span style={{ fontSize: "13px" }}>{line.inv}</span>
                ) : undefined
              }
              placement="top"
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -3],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                variant="tableText"
                sx={{
                  ...textStyle,
                  mr: line.typ == 1 ? "0px" : "8px",
                  //ml: !getLeftBorder(line) ? "0px" : "-4px",
                }}
              >
                {line.inv || "-"}
              </Typography>
            </Tooltip>
            {line.typ == 1 && (
              <Tooltip
                text={
                  <span style={{ fontSize: "13px" }}>Zálohová faktura</span>
                }
                placement="top"
                arrow
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -3],
                        },
                      },
                    ],
                  },
                }}
              >
                <NoteAddRoundedIcon
                  sx={{
                    color: placeholderGrey,
                    width: "26px",
                    height: "26px",
                    ml: "auto",
                    mr: "6px",
                  }}
                />
              </Tooltip>
            )}
          </Grid>

          <Grid
            item
            xs={odberatelLine}
            sm={odberatelLine}
            sx={{ ...tableRowStyle }}
          >
            {!removeKontaktIcon && (
              <Box
                sx={{
                  pl: { xs: "15px", sm: "20px", md: "35px", lg: "45px" },
                  pr: "8px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Tooltip
                  enterTouchDelay={400}
                  enterDelay={600}
                  enterNextDelay={400}
                  key={"SDCTooltip"}
                  text={"Detail kontaktu" || undefined}
                  placement="bottom"
                >
                  <PersonSearchRoundedIcon
                    sx={{
                      fill: placeholderGrey,
                      height: "28px",
                      width: "28px",
                      "&:hover": {
                        fill: navbarBlue,
                        cursor: "pointer",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`/adresar/detail?uid=${line.customer}`);
                    }}
                  />
                </Tooltip>
              </Box>
            )}
            <Typography
              variant="tableText"
              sx={{
                ...textStyle,
                paddingLeft: removeKontaktIcon ? paddingLeft : "0px",
              }}
            >
              {line.name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} sx={{ ...tableRowStyle }}>
            <Typography variant="tableText" sx={{ ...textStyle }}>
              {isPlatce == 1
                ? getCostVisual(line.price, line.iso)
                : getCostVisual(
                    line.tax ? line.price + line.tax : line.price,
                    line.iso
                  )}
            </Typography>
          </Grid>
          {isPlatce == 1 && (
            <Grid item xs={4} sm={4} sx={{ ...tableRowStyle }}>
              <Typography variant="tableText" sx={{ ...textStyle }}>
                {line.tax
                  ? getCostVisual(
                      line.tax ? line.price + line.tax : line.price,
                      line.iso
                    )
                  : "–"}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={3}
            sm={3}
            sx={{ ...tableRowStyle, justifyContent: "center" }}
          >
            <Tooltip
              text={
                line.payDate ? (
                  <span style={{ fontWeight: "normal" }}>
                    <b>Datum splatnosti</b>
                    <br />
                    {timestampToDateVisual(line.dueDate)}
                    <br />
                    <b>Datum úhrady</b>
                    <br />
                    {timestampToDateVisual(line.payDate)}
                  </span>
                ) : undefined
              }
            >
              <Typography variant="tableText" sx={{ textAlign: "center" }}>
                {line.payDate
                  ? "uhrazeno"
                  : timestampToDateVisual(line.dueDate)}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              ...tableRowStyle,
              borderRight: 0,
              display: "flex",
              justifyContent: "center",
              pr: "0px",
              //"&:hover": {},
            }}
            /*onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}*/
          >
            <ActionMenu
              list={getActionMenu(index).filter(Boolean)}
              onClickGetIndex={onClickGetIndex}
              index={index}
              sxBox={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <IconButton
                dontHover={false}
                sxProps={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0",
                  ml: "6px",
                }}
                scale={1.1}
                icon={
                  <MoreHorizRoundedIcon
                    sx={{ fill: navbarBlue, width: "30px", height: "30px" }}
                  />
                }
              />
            </ActionMenu>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default withRouter(FakturaTable);
