import React, { useState } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import { ReactComponent as Setup } from "../../Design/Graphics/svg/Navigation/Setup.svg";
import { ReactComponent as Support } from "../../Design/Graphics/svg/Navigation/Support.svg";
// PHONE
import { ReactComponent as Add } from "../../Design/Graphics/svg/Navigation/Phone/Add.svg";
import { ReactComponent as Bookmark } from "../../Design/Graphics/svg/Navigation/Phone/Bookmark.svg";
import { ReactComponent as Contact } from "../../Design/Graphics/svg/Navigation/Phone/Contact.svg";
import { ReactComponent as Dollar } from "../../Design/Graphics/svg/Navigation/Phone/Dollar.svg";
import { ReactComponent as Graph } from "../../Design/Graphics/svg/Navigation/Phone/Graph.svg";

function ResizeIcon({ icon }) {
  let editedIcon = React.cloneElement(icon, {
    style: {
      fill: "black",
      width: "30px",
      height: "30px",
    },
  });

  return editedIcon;
}

// MAIN NAVBAR

export const mainNavigation = [
  {
    name: "Nová faktura",
    path: "/faktura/nova",
    icon: <AddCircleOutlineRoundedIcon />,
    tooltip: "Zde můžete vytvářet faktury.",
  },
  {
    name: "Přehled",
    path: "/faktura/list",
    icon: <LibraryBooksRoundedIcon />,
    tooltip: "Zde naleznete přehled všech svých faktur.",
  },
  {
    name: "Adresář",
    path: "/adresar/list",
    icon: <FolderSharedRoundedIcon />,
    tooltip: "Zde naleznete své kontakty.",
  },
  {
    name: "Ceník",
    path: "/cenik/list",
    icon: <PaymentsRoundedIcon />,
    tooltip: "Zde můžete vytvářet a třídit ceníkové položky.",
  },
  {
    name: "Statistika",
    path: "/statistika",
    icon: <QueryStatsRoundedIcon />,
    tooltip: "Zde naleznete statistiky vaší fakturace.",
  },
];

// PROFILE BUTTON

export const navigationMenu = [
  {
    name: "Nastavení",
    icon: <Setup />,
    text: "Správa předplatného, nastavní výchozích hodnot, vzhled faktury, nastavení emailu...",
    phoneText: "Správa předplatného, nastavní výchozích hodnot, ...",
    path: "/settings",
  },
  {
    name: "Podpora",
    icon: <Support />,
    text: "Kontaktní formulář, návody, rady a tipy pro ulehčení práce...",
    phoneText: "Kontakt, návody, rady a tipy pro ulehčení práce",
    path: "https://profaktura.cz/podpora",
    fullPath: true,
    podpora: true,
  },
];

// PROFILE MOBILE

export const navigationMobile = [
  {
    name: "Nová faktura",
    icon: <Add />,
    text: "Formulář pro tvorbu nové faktury a zálohovky",
    path: "/faktura/nova",
  },
  {
    name: "Přehled faktur",
    icon: <Bookmark />,
    text: "Přehled, třídění faktur a vyhledávání faktur",
    path: "/faktura/list",
  },
  {
    name: "Ceník",
    icon: <Dollar />,
    text: "Ceník, tvorba položek ceníku a export ceníku",
    path: "/cenik/list",
  },
  {
    name: "Adresář",
    icon: <Contact />,
    text: "Adresář kontaktů, tvorba kontaktů a export kontaktů",
    path: "/adresar/list",
  },
  {
    name: "Statistika",
    icon: <Graph />,
    text: "Grafy, přehledy statistiky a cashflow",
    path: "/statistika",
  },
];

// SECONDARY NAVBARS

export const smallNavigation = [
  {
    path: "/faktura",
    routes: [
      //{ name: "Nová faktura", path: "/faktura/nova" },
      {
        name: "Všechny faktury",
        path: "/faktura/list",
        tooltip: "Zde naleznete přehled všech svých faktur.",
      },
      {
        name: "Koncepty",
        path: "/faktura/concepts",
        tooltip: "Zde naleznete nedokončené faktury.",
      },
      {
        name: "Šablony",
        path: "/sablona/list",
        tooltip: "Zde můžete vytvářet vzory faktur pro opakované použití.",
      },
      {
        name: "Export",
        path: "/faktura/export",
        tooltip:
          "Zde můžete hromadně stahovat faktury z vámi vybraného období.",
      },
    ],
  },
  {
    path: "/cenik",
    routes: [
      {
        name: "Nová položka",
        path: "/cenik/novy",
        tooltip: "Zde můžete přidat novou položku do svého ceníku.",
      },
      {
        name: "Všechny položky",
        path: "/cenik/list",
        tooltip: "Zde naleznete všechny ceníkové položky.",
      },
      {
        name: "Export",
        path: "/cenik/export",
        tooltip: "Zde si můžete stáhnout tabulku s ceníkovými položkami.",
      },
    ],
  },
  {
    path: "/adresar",
    routes: [
      {
        name: "Nový kontakt",
        path: "/adresar/novy",
        tooltip: "Zde můžete přidat nový kontakt do svého adresáře.",
      },
      {
        name: "Všechny kontakty",
        path: "/adresar/list",
        tooltip: "Zde naleznete přehled všech svých kontaktů.",
      },
      {
        name: "Export",
        path: "/adresar/export",
        tooltip: "Zde si můžete stáhnout tabulku se svými kontakty.",
      },
    ],
  },
  {
    path: "/",
    routes: [
      //{ name: "Nová faktura", path: "/faktura/nova" },
      { name: "Všechny faktury", path: "/faktura/list" },
      { name: "Šablony faktur", path: "/sablona/list" },
      { name: "Export", path: "/faktura/export" },
    ],
  },
  {
    path: "/settings/banka",
    routes: [
      {
        name: "Bankovní účty",
        path: "/settings/banka/list",
        tooltip: "Zde můžete upravovat své bankovní účty.",
      },
      {
        name: "Nový účet",
        path: "/settings/banka/pridat",
        tooltip: "Zde můžete přidat bankovní účet.",
      },
    ],
  },
  {
    path: "/settings/fakturace",
    routes: [
      {
        name: "Výchozí hodnoty",
        path: "/settings/fakturace",
        tooltip:
          "Zde můžete upravovat výchozí hodnoty formuláře tvorby faktur.",
      },
      {
        name: "Číslování faktur",
        path: "/settings/cislovani",
        tooltip: "Zde můžete upravit způsob číslování svých faktur.",
      },
      {
        name: "Číslování zálohovek",
        path: "/settings/zalohovky",
        tooltip: "Zde můžete upravit způsob číslování svých zálohových faktur.",
      },
    ],
  },
  {
    path: "/subscription",
    routes: [
      {
        name: "Správa",
        path: "/subscription",
        tooltip: "Zde naleznete informace o svém předplatném.",
      },
      {
        name: "Nový uživatel",
        path: "/subscription/subuser",
        tooltip:
          "Zde můžete ke správě svého účtu přizvat účetní a další uživatele.",
      },
    ],
  },
];
