import {
  doc,
  getDoc,
  setDoc,
  writeBatch,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import generateUID from "../../generateUID";
import { db, isUser } from "../../init";
import { getControlUid } from "../../../Core/Pages/Settings/SubscriptionPage/SubUsers/Access";

const litelar = `\uf8ff`;

/**********************/
/**** CHANGE  DATA ****/
/**********************/

export const templDataFixToSave = (data) => {
  let fakturaDataSorted = { ...data };

  // Removing data useless for saving

  delete fakturaDataSorted.dueDate;
  delete fakturaDataSorted.unit;

  if (fakturaDataSorted.showNote && fakturaDataSorted.note) {
    delete fakturaDataSorted.showNote;
  } else {
    delete fakturaDataSorted.showNote;
    delete fakturaDataSorted.note;
  }

  return fakturaDataSorted;
};

/**********************/
/**** SAVING  DATA ****/
/**********************/

export const saveTemplate = async (data, editData) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!isUser()) {
        return reject({ error: "auth" });
      }

      const templatesRef = doc(
        db,
        "faktury",
        `${getControlUid()}`,
        "template",
        "list"
      );

      let uid = generateUID();
      const isEdit = Boolean(editData && editData.uid);
      if (isEdit) {
        uid = editData.uid;
      }

      await setDoc(
        templatesRef,
        {
          [uid]: {
            ...data,
            createdAt: isEdit ? editData.createdAt : new Date(),
          },
        },
        { merge: true }
      );

      return resolve();
    } catch (err) {
      return reject(err);
    }
  });
};

/**********************/
/**** GET LIST DATA ***/
/**********************/

export const getTemplates = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!isUser()) {
        return reject({ error: "auth" });
      }

      const templateRef = doc(
        db,
        "faktury",
        `${getControlUid()}`,
        "template",
        "list"
      );

      const docSnap = await getDoc(templateRef);

      if (docSnap.exists()) {
        const docData = docSnap.data();
        const keys = Object.keys(docData);
        let finalArr = [];
        for (let i = 0; i < keys.length; i++) {
          finalArr.push({ uid: keys[i], ...docData[keys[i]] });
        }
        return resolve(finalArr);
      } else {
        return resolve([]);
      }
    } catch (err) {
      return reject(err);
    }
  });
};

/**********************/
/***** DELETE DATA ****/
/**********************/

export const deleteTemplate = async (templateUid) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!isUser()) {
        return reject({ error: "auth" });
      }

      const templateRef = doc(
        db,
        "faktury",
        `${getControlUid()}`,
        "template",
        "list"
      );

      await updateDoc(templateRef, { [templateUid]: deleteField() });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });
};
