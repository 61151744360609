import { lazy } from "react";

// Adresar
const AdresarAdd = lazy(() =>
  import("../../Core/Pages/Main/Adresar/AdresarAdd")
);
const AdresarEdit = lazy(() =>
  import("../../Core/Pages/Main/Adresar/AdresarEdit")
);
const AdresarList = lazy(() =>
  import("../../Core/Pages/Main/Adresar/AdresarList")
);
const AdresarDetail = lazy(() =>
  import("../../Core/Pages/Main/Adresar/AdresarDetail/AdresarDetail")
);
const AdresarExport = lazy(() =>
  import("../../Core/Pages/Main/Adresar/AdresarExport/AdresarExport")
);

// Cenik
const CenikAdd = lazy(() => import("../../Core/Pages/Main/Cenik/CenikAdd"));
const CenikEdit = lazy(() => import("../../Core/Pages/Main/Cenik/CenikEdit"));
const CenikList = lazy(() => import("../../Core/Pages/Main/Cenik/CenikList"));
const CenikExport = lazy(() =>
  import("../../Core/Pages/Main/Cenik/CenikExport/CenikExport")
);

// Faktury
const ConceptsList = lazy(() =>
  import("../../Core/Pages/Main/Faktura/Concepts/ConceptsList")
);
const FakturaAdd = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaAdd/FakturaAdd")
);
const FakturaList = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaList_stats/FakturaList")
);
const FakturaDetail = lazy(() =>
  import(
    "../../Core/Pages/Main/Faktura/FakturaDetail/DetailMainPage/FakturaDetail"
  )
);
const FakturaExport = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaExport/FakturaExport")
);
const FakturaSend = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaSend/FakturaSend")
);
const ReminderSend = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaSend/ReminderSend/ReminderSend")
);

// Sablony faktur
const TemplateAdd = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaTemplates/TemplateAdd")
);
const TemplateList = lazy(() =>
  import("../../Core/Pages/Main/Faktura/FakturaTemplates/TemplateList")
);

// Emaily
const EmailSettingsControl = lazy(() =>
  import(
    "../../Core/Pages/Main/Faktura/FakturaSend/EmailTemplates/EmailSettings/EmailSettingsControl"
  )
);
const ReminderTemplate = lazy(() =>
  import(
    "../../Core/Pages/Main/Faktura/FakturaSend/EmailTemplates/ReminderTemplates/ReminderTemplate"
  )
);
const EmailTemplate = lazy(() =>
  import(
    "../../Core/Pages/Main/Faktura/FakturaSend/EmailTemplates/EmailTemplates/EmailTemplate"
  )
);
const EmailSendSuccess = lazy(() =>
  import(
    "../../Core/Pages/Main/Faktura/FakturaSend/EmailTemplates/EmailSendSuccess"
  )
);

const PublicView = lazy(() =>
  import("../../Core/Pages/Main/Faktura/PublicView/PublicView")
);
const StatistikaPage = lazy(() =>
  import("../../Core/Pages/Main/Statistika/StatistikaPage")
);

const mainRoutes = [
  // FAKTURA
  {
    path: "/faktura/nova",
    element: <FakturaAdd />,
    permission: "cInv",
  },
  {
    path: "/faktura/uprava",
    element: <FakturaAdd />,
    permission: "eInv",
  },
  {
    path: "/faktura/duplikace",
    element: <FakturaAdd />,
    permission: "cInv",
  },
  {
    path: "/faktura/list",
    element: <FakturaList />,
  },
  {
    path: "/faktura/detail",
    element: <FakturaDetail />,
  },
  {
    path: "/faktura/export",
    element: <FakturaExport />,
    permission: "xInv",
  },
  {
    path: "/faktura/odeslat",
    element: <FakturaSend />,
    permission: "sMai",
  },
  {
    path: "/faktura/upominka",
    element: <ReminderSend />,
    permission: "sRem",
  },
  {
    path: "/faktura/odeslana",
    element: <EmailSendSuccess />,
  },
  {
    path: "/faktura/concepts",
    element: <ConceptsList />,
  },
  // FAKTURA - PUBLIC VIEW
  {
    path: "/view/faktura/",
    element: <PublicView />,
    public: true,
    noNavbar: true,
  },
  // FAKTURA - SABLONY
  {
    path: "/sablona/nova",
    element: <TemplateAdd />,
    permission: "cTem",
  },
  {
    path: "/sablona/uprava",
    element: <TemplateAdd />,
    permission: "cTem",
  },
  {
    path: "/sablona/list",
    element: <TemplateList />,
    permission: ["cTem", "dTem"],
  },
  // CENIK
  {
    path: "/cenik/list",
    element: <CenikList />,
    permission: "vCen",
  },
  {
    path: "/cenik/novy",
    element: <CenikAdd />,
    permission: "cCen",
  },
  {
    path: "/cenik/uprava",
    element: <CenikEdit />,
    permission: "cCen",
  },
  {
    path: "/cenik/export",
    element: <CenikExport />,
    permission: "xCen",
  },
  // KONTAKT
  {
    path: "/adresar/list",
    element: <AdresarList />,
    permission: "vAdr",
  },
  {
    path: "/adresar/novy",
    element: <AdresarAdd />,
    permission: "cAdr",
  },
  {
    path: "/adresar/uprava",
    element: <AdresarEdit />,
    permission: "cAdr",
  },
  {
    path: "/adresar/detail",
    element: <AdresarDetail />,
    permission: "dAdr",
  },
  {
    path: "/adresar/export",
    element: <AdresarExport />,
    permission: "xAdr",
  },
  // STATISTIKA
  {
    path: "/statistika",
    element: <StatistikaPage />,
  },
  // Emaily
  {
    path: "/settings/email",
    element: <EmailSettingsControl />,
    noSecNavbar: true,
    permission: "eSet",
  },
  {
    path: "/settings/email/upominka",
    element: <ReminderTemplate />,
    permission: "eSet",
  },
  {
    path: "/settings/email/sablona",
    element: <EmailTemplate />,
    permission: "eSet",
  },
];

export default mainRoutes;
